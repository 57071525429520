import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Logout from "../common/logout";
import CustomHome from "../common/customHome";
import ProductoNew from "../productos/productoNew";
import ProductoQuery from "../productos/productoQuery";
import ProductoEdit from "../productos/productoEdit";

class Template extends Component {
  render() {
    const { user } = this.props;
    return (
      <Switch>
        <Route path="/producto/:id" component={ProductoEdit} />
        <Route path="/productos" component={ProductoQuery} />
        <Route path="/producto-nuevo" component={ProductoNew} />
        <Route path="/logout" component={Logout} />
        <Route path="/home" component={CustomHome} />
        <Route exact path="/">
          {user.nombre ? <CustomHome /> : <Logout />}
        </Route>
      </Switch>
    );
  }
}

export default Template;
