import React, { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ProductoTable = (props) => {
  const { list, actionBodyTemplate, loading } = props;
  const dt = useRef(null);

  return (
    <DataTable
      ref={dt}
      value={list}
      loading={loading}
      stripedRows
      emptyMessage="No hay registro(s)"
    >
      <Column
        field="codigo"
        header="Código"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="nombre"
        header="Nombre"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="categoriaNombre"
        header="Categoria Padre"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="categoriaHijaNombre"
        header="Categoria Hija"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="cantidad"
        header="Cantidad"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="talla"
        header="Talla"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column
        field="colorNombre"
        header="Color"
        sortable
        filter
        filterMatchMode="contains"
      />
      <Column body={actionBodyTemplate} />
    </DataTable>
  );
};

export default ProductoTable;
