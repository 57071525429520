import http from "../common/httpService";

const api = "/categoria";

async function findPadres() {
  const { data: result } = await http.get(`${api}/nivel/0`);
  return prepareList(result);
}

async function findHijas() {
  const { data: result } = await http.get(`${api}/nivel/1`);
  return prepareList(result);
}

function prepareList(list) {
  let lst = [];
  for (const model of list) {
    lst.push({
      label: model.nombre,
      value: model._id + "-" + model.nombre,
    });
  }
  return lst;
}

export default {
  findPadres,
  findHijas,
};
