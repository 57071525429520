import React from "react";
import { Link } from "react-router-dom";
import { Collapsible, CollapsibleItem, Icon } from "react-materialize";

const Menu = (props) => {
  const user = props.user;

  return (
    <ul id="slide-out" className="sidenav">
      <li style={{ backgroundColor: "#1c1c1c", color: "#fff" }}>
        <div className="user-view">
          <span
            className="name center text-bold"
            style={{ marginTop: "0px", fontSize: "16px" }}
          >
            <div>{user.nombre}</div>
            <div>{user.correo}</div>
          </span>
          <span className="email center white-text text-bold"></span>
        </div>
      </li>
      <li style={{ marginLeft: "-12px" }}>
        <Link to="/" className="sidenav-close">
          <i
            className="material-icons"
            style={{ margin: "0 18px 0 0", color: "#000000de" }}
          >
            home
          </i>
          <span style={{ color: "#000000de" }}>Pantalla de inicio</span>
        </Link>
      </li>
      <li>
        <Collapsible accordion>
          <CollapsibleItem
            expanded={false}
            header="Productos"
            icon={<Icon>storefront</Icon>}
            node="ul"
          >
            <ul>
              <li>
                <Link to="/producto-nuevo" className="sidenav-close">
                  <i className="material-icons">note_add</i>
                  Nuevo registro
                </Link>
              </li>
              <li className="sidenav-close">
                <Link to="/productos" className="sidenav-close">
                  <i className="material-icons">search</i>
                  Consulta
                </Link>
              </li>
            </ul>
          </CollapsibleItem>
        </Collapsible>
      </li>
      <li className="sidenav-close">
        <Link to="/logout" style={{ padding: "0 20px" }}>
          <i
            className="material-icons"
            style={{ margin: "0 20px 0 0", color: "#000000de" }}
          >
            exit_to_app
          </i>
          <span style={{ color: "#000000de" }}>[ Salir ]</span>
        </Link>
      </li>
    </ul>
  );
};

export default Menu;
