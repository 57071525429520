import React, { useState, useEffect, useRef } from "react";
import ProductoService from "../../service/modules/productoService";
import util from "../../service/common/util";
import ProductoForm from "./productoForm";
import { Dialog } from "primereact/dialog";
import { Carousel } from "primereact/carousel";
import { Toast } from "primereact/toast";

const ProductoEdit = (props) => {
  const toast = useRef(null);
  const [form, setForm] = useState("");
  const [errors, setErrors] = useState({});
  const [codes, setCodes] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [idFoto, setIdFoto] = useState("");
  const [showDeleteFoto, setShowDeleteFoto] = useState(false);
  const [idProducto, setIdProducto] = useState("");

  const findById = async (id) => {
    const model = await ProductoService.findById(id);
    fillForm(model);
  };

  useEffect(async () => {
    const id = props.match.params.id;
    setIdProducto(id);
    await findById(id);
  }, []);

  const fillForm = (model) => {
    const form = { ...model, color: `${model.color}-${model.colorNombre}` };
    setForm(form);
  };

  const uploadFiles = async () => {};

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const deleteImage = async () => {
    const result = await ProductoService.deleteFoto(idProducto, idFoto);
    if (result.type === "OK") {
      await findById(idProducto);
      setShowDeleteFoto(false);
      util.success(toast, "Se ha eliminado la foto");
    } else
      util.error(toast, "Ha ocurrido un error al tratar de eliminar la foto");
  };

  const productTemplate = (product) => {
    const base64String = toBase64(product.img.data);
    return (
      <div className="product-item">
        <div className="product-item-content">
          <div className="mb-3">
            <img
              style={{ width: "50%" }}
              src={`data:${product.img.contentType};base64,${base64String}`}
            />
          </div>
          <div>
            <div className="car-buttons mt-5">
              <button
                className="btn-floating waves-effect waves-light red"
                onClick={(e) => prepareDeleteFoto(product._id)}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function toBase64(arr) {
    arr = new Uint8Array(arr.data);
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
  }

  function prepareDeleteFoto(id) {
    setIdFoto(id);
    setShowDeleteFoto(true);
  }

  return (
    <>
      <Dialog
        visible={showDeleteFoto}
        onHide={(e) => setShowDeleteFoto(false)}
        showHeader={false}
      >
        {idFoto && (
          <div
            className="row"
            style={{ paddingTop: "15px", marginBottom: "0px" }}
          >
            <div className="col s12">
              ¿Esta seguro que desea eliminar la imagen?
              <div style={{ marginTop: "10px" }}>
                <button
                  className="btn-small waves-effect waves-light grey darken-3"
                  onClick={deleteImage}
                >
                  <i className="material-icons left">check</i>
                  Si
                </button>
                <button
                  className="btn-small waves-effect waves-light grey darken-3"
                  style={{ marginLeft: "15px" }}
                  onClick={(e) => setShowDeleteFoto(false)}
                >
                  <i className="material-icons left">cancel</i>
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
      <div className="row" style={{ marginBottom: "0px" }}>
        <div className="col s12 m6">
          <h4 className="left text-bold">Editar Producto</h4>
        </div>
        <div className="col s12 m6 right-align">
          <h4>
            <button
              className="btn waves-effect waves-light black darken-4"
              onClick={(e) => props.history.push("/productos")}
            >
              <i className="material-icons left">arrow_back</i>
              Regresar
            </button>
          </h4>
        </div>
      </div>
      <div className="container">
        <div className="card" style={{ padding: "15px" }}>
          <div className="row">
            <div className="col s12">
              <ProductoForm
                form={form}
                setForm={setForm}
                errors={errors}
                codes={codes}
                setCodes={setCodes}
                uploadFiles={uploadFiles}
                disabled={disabled}
                setDisabled={setDisabled}
                activateLabel={true}
              />
            </div>
          </div>
          {form.fotos.length > 0 && (
            <div className="row">
              <div className="col s12 carousel-demo">
                <Carousel
                  value={form.fotos}
                  numVisible={3}
                  numScroll={1}
                  header={
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        Im&aacute;genes Actuales:
                      </span>{" "}
                      <span>Elimine las que ya no aplican.</span>
                    </>
                  }
                  itemTemplate={productTemplate}
                  responsiveOptions={responsiveOptions}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductoEdit;
